.btn-primary, .btn-secondary {
  transition: all 0.5s ease-in-out;
}

.btn-primary, .btn-secondary {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #2b5393 !important;
  border-radius: 50px;
}

:root {
  font-size: 62.5%;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
}
* :focus {
  outline: none;
}

body {
  background-color: #fff;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
  font-size: inherit;
}
a:hover {
  color: #2e2e2e;
  text-decoration: unset;
}

img {
  display: block;
  max-width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

.btn-primary {
  color: white;
  background-color: #2b5393;
  font-size: 1rem;
  width: 109px;
  min-height: 41px;
  box-shadow: 0px 4px 4px rgba(43, 83, 147, 0.1);
}
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 83, 147, 0.65);
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  color: #2b5393 !important;
  background-color: white !important;
  border: 1px solid #2b5393 !important;
}

.btn-secondary {
  color: #2b5393;
  background-color: transparent;
  font-size: 0.875rem;
  width: 147px;
  min-height: 41px;
  box-shadow: 0px 4px 4px rgba(43, 83, 147, 0.1);
}
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
  color: white !important;
  background-color: #2b5393 !important;
  border: 1px solid #2b5393 !important;
}
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 83, 147, 0.65);
}

.form-control {
  font-size: 1rem;
  border-radius: 6px;
  border: unset;
}

.input_invalid {
  border: 1px solid red;
}

.title {
  margin-bottom: unset;
}
.title::after {
  content: "";
  display: block;
  width: 66px;
  height: 3px;
  margin: 0.2em auto;
  background-color: #2b5393;
}

.placeholder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.map {
  box-shadow: 0px -5px 15px 2px rgba(0, 0, 0, 0.15);
}
.map .embed-responsive {
  max-height: 283px;
}

.toast-message, .toast-title {
  color: white;
}

.image img {
  max-width: 100%;
}
.image-style-side {
  float: left;
  margin-right: 1rem;
}

.page-link:hover, .page-link {
  color: #2b5393;
}

.page-item.active > .page-link {
  background-color: #274b84;
  border-color: #274b84;
}

.header__top-bar {
  color: #2e2e2e;
  font-size: 2.1rem;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
}
@media (min-width: 1200px) {
  .header__top-bar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background-color: white;
  }
}
.header__top-bar .header-mobile {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  align-items: center;
}
@media (min-width: 600px) {
  .header__top-bar .header-mobile {
    display: none;
  }
}
.header__top-bar .header-mobile .list-socials {
  display: flex;
  font-size: 18px;
  color: #6a6a6a;
  gap: 24px;
  justify-content: center;
}
.header__top-bar .header-mobile .time {
  font-size: 14px;
  font-weight: 700;
  color: #6a6a6a;
  text-align: center;
}
.header__top-bar .header-mobile .headline {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #161815;
}
.header__top-bar .header-mobile .headline::after {
  content: "";
  display: block;
  width: 66px;
  height: 3px;
  margin: 0.5rem auto;
  background-color: #ff0110;
}
@media (min-width: 1200px) {
  .header__top-bar .header-mobile {
    display: none;
  }
}
.header .logo-container-mobile {
  margin-bottom: 24px;
  margin-top: 24px;
}
.header .logo-container-mobile img {
  margin: 0 auto;
}
@media (min-width: 600px) {
  .header .logo-container-mobile {
    display: none;
  }
}
.header__section .logo-container {
  margin-bottom: 24px;
  margin-top: 24px;
}
.header__section .logo-container img {
  margin: 0 auto;
}
@media (max-width: 599px) {
  .header__section .logo-container {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1799px) {
  .header__section .logo-container {
    padding: 32px 0;
  }
}
.header__section .header-infos {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 599px) {
  .header__section .header-infos {
    display: none;
  }
}
.header__section .header-infos .headline {
  font-size: 18px;
  font-weight: bold;
  color: #161815;
}
@media (max-width: 991px) {
  .header__section .header-infos .headline {
    font-size: 16px;
  }
}
.header__section .header-infos .headline::after {
  content: "";
  display: block;
  width: 66px;
  height: 3px;
  margin: 0.5rem 0;
  background-color: #ff0110;
}
.header__section .list-socials {
  display: flex;
  font-size: 24px;
  color: #6a6a6a;
  gap: 24px;
}
.header__section .time {
  font-size: 18px;
  font-weight: 700;
  color: #6a6a6a;
}
@media (max-width: 991px) {
  .header__section .time {
    font-size: 16px;
  }
}

@media (max-width: 599px) {
  .header__nav {
    margin-right: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .header__nav .row {
    justify-content: center;
  }
}

.nav__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
@media (min-width: 600px) and (max-width: 899px) {
  .nav__list {
    font-size: 16px;
  }
}
@media (max-width: 1199px) {
  .nav__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 4px;
    gap: 0;
  }
  .nav__list .nav__item {
    padding: 8px 0;
  }
}
@media (max-width: 1199px) and (max-width: 599px) {
  .nav__list .nav__item {
    border-bottom: solid 1px #d6d6d6;
    width: 100%;
    text-align: center;
  }
}
.nav__list--drop {
  top: 100%;
  left: 0;
  z-index: 100;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  gap: 0;
  padding: 0.5rem;
}
.nav__item {
  padding: 8px 0;
  transition: all 0.7s;
  position: relative;
}
@media (max-width: 599px) {
  .nav__item {
    border-bottom: solid 1px #d6d6d6;
  }
}
.nav__item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: red;
}
.nav__item:hover::after {
  width: 100%;
}
.nav__item--drop {
  position: relative;
}
.nav__item--drop-item {
  position: relative;
  width: 100%;
}
.nav__item--drop-item a {
  padding: 0.5rem 0;
}
.nav__item--drop-item::after {
  background-color: #ff0110;
}
.nav__item--drop:hover {
  background-color: white;
}
.nav__item--drop:hover span {
  color: #ff0110 !important;
}
.nav__item--drop:hover > ul {
  opacity: 1;
  visibility: visible;
}
.nav__link {
  padding: 0 0.5rem;
}
.nav__link:hover {
  color: currentColor;
}
@media (min-width: 1200px) {
  .nav__content {
    display: block;
  }
  .nav__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .nav__list--drop {
    position: absolute;
    min-width: 200px;
    opacity: 0;
    visibility: hidden;
    background-color: white;
  }
  .nav__list--drop a {
    color: #666666 !important;
  }
  .nav__item--drop-item a {
    align-items: flex-start;
  }
  .nav__item--drop-item:not(:last-of-type) {
    border-bottom: 1px solid #e8e8e8;
    margin-left: unset;
  }
  .nav__item, .nav__link {
    height: 100%;
  }
}

.nav-mobile {
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 100;
  width: 100%;
  height: 100%;
}
.nav-mobile__overlay {
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.nav-mobile__close {
  text-align: right;
  margin: 2rem 1rem 0 0;
  color: #ff0110;
}
.nav-mobile__button {
  width: 100%;
  padding: 16px;
  color: #2e2e2e;
}
@media (min-width: 1200px) {
  .nav-mobile {
    position: static;
    width: 100%;
    background-color: transparent;
  }
  .nav-mobile__button, .nav-mobile__close {
    display: none;
  }
}
.nav-mobile__active, .nav-mobile__active > .nav-mobile__overlay {
  right: 0;
}

.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 230px;
  background-image: url("../../../images/placeholder/web/banner-pages.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 600px) and (max-width: 1199px) {
  .banner {
    margin-top: 32px;
  }
}
.banner__figure {
  margin-bottom: 8px;
}
.banner__image {
  width: 0px;
}
.banner__title {
  font: 900 38px/40px "Roboto", sans-serif;
  color: #161815;
  text-align: center;
}
@media (max-width: 599px) {
  .banner__title {
    font-size: 40px;
  }
}
.banner__title::after {
  content: "";
  display: block;
  height: 4px;
  margin: 1.2rem auto;
  background-color: #ff0110;
  border-radius: 8px;
}

.footer .rights {
  padding: 12px 0;
  background-color: #ff0110;
}
.footer .rights__info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  gap: 16px;
}
.footer .rights__info p {
  font-size: 14px;
  color: #fff;
}
@media (min-width: 1200px) {
  .footer .rights__info {
    flex-direction: row;
    text-align: left;
    gap: 0;
  }
}

.social-list__item::after, .social-list__item:first-of-type::before {
  content: "";
  position: absolute;
  top: 0;
  z-index: 1;
  display: block;
  height: 100%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}

.social-list__item {
  position: relative;
  display: inline-block;
  height: 100%;
}
.social-list__item a {
  display: flex;
  align-items: center;
  height: 100%;
  color: white;
  padding: 0 0.8rem;
  font-size: 1.25rem;
}
.social-list__item a:hover {
  background-color: #2b5393;
}
.social-list__item:first-of-type::before {
  left: 0;
}
.social-list__item::after {
  right: 0;
}

.whats-link {
  position: fixed;
  right: 1em;
  bottom: 6em;
  z-index: 10;
  width: 64px;
  height: 64px;
}