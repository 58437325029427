@use "../abstracts" as *;

.title {
    margin-bottom: unset;

    &::after {
        content: "";
        display: block;
        width: 66px;
        height: 3px;
        margin: 0.2em auto;
        background-color: color(primary);
    }
}

.placeholder {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.map {
    box-shadow: 0px -5px 15px 2px rgba(0, 0, 0, 0.15);
    .embed-responsive {
        max-height: 283px;
    }
}
