@use "../abstracts" as *;

%divider {
    content: "";
    position: absolute;
    top: 0;
    z-index: 1;
    display: block;
    height: 100%;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.3);
}

.social-list {
    &__item {
        position: relative;
        display: inline-block;
        height: 100%;

        a {
            display: flex;
            align-items: center;
            height: 100%;
            color: white;
            padding: 0 0.8rem;
            font-size: size(text, 500);

            &:hover {
                background-color: color(primary);
            }
        }

        &:first-of-type {
            &::before {
                @extend %divider;
                left: 0;
            }
        }

        &::after {
            @extend %divider;
            right: 0;
        }
    }
}
