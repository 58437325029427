@use "../abstracts" as *;

.btn-primary {
    @include button(primary, size(text), white, 109px, 41px);
    @extend %buttonDefault;

    &:hover,
    &:focus,
    &:active {
        color: color(primary) !important;
        background-color: white !important;
        border: 1px solid color(primary) !important;
    }
}

.btn-secondary {
    @extend %buttonDefault;

    color: color(primary);
    background-color: transparent;
    font-size: size(text, 200);
    width: 147px;
    min-height: 41px;
    box-shadow: 0px 4px 4px color(primary, 400, 0.1);

    &:hover,
    &:focus,
    &:active {
        color: white !important;
        background-color: color(primary) !important;
        border: 1px solid color(primary) !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem color(primary, 400, 0.65);
    }
}
