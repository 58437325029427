@use "../abstracts" as *;

.header {
    &__top-bar {
        color: color(primary, gray-3);
        font-size: 2.1rem;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);

        @include for-desktop-up {
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 10;
          background-color: color(grayScale, white);
        }

        .header-mobile {
            display: flex;
            justify-content: center;
            margin-top: 16px;
            align-items: center;

            @include for-tablet-portrait-up {
                display: none;
            }

            .list-socials {
                display: flex;
                font-size: 18px;
                color: color(primary, gray-1);
                gap: 24px;
                justify-content: center;
            }

            .time {
                font-size: 14px;
                font-weight: 700;
                color: color(primary, gray-1);
                text-align: center;
            }

            .headline {
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                color: color(primary, gray-4);
                &::after {
                    content: "";
                    display: block;
                    width: 66px;
                    height: 3px;
                    margin: 0.5rem auto;
                    background-color: color(primary, red-1);
                }
            }

            @include for-desktop-up {
                display: none;
            }
        }
    }

    .logo-container-mobile {
        margin-bottom: 24px;
        margin-top: 24px;

        img {
            margin: 0 auto;
        }

        @include for-tablet-portrait-up {
            display: none;
        }
    }

    &__section {
        .logo-container {
            margin-bottom: 24px;
            margin-top: 24px;

            img {
                margin: 0 auto;
            }

            @include for-phone-only {
                display: none;
            }

            @include for-desktop-only {
                padding: 32px 0;
            }
        }

        .header-infos {
            display: flex;
            justify-content: flex-end;

            @include for-phone-only {
                display: none;
            }

            .headline {
                font-size: 18px;
                font-weight: bold;
                color: color(primary, gray-4);

                @include for-phone-and-tablet {
                    font-size: 16px;
                }

                &::after {
                    content: "";
                    display: block;
                    width: 66px;
                    height: 3px;
                    margin: 0.5rem 0;
                    background-color: color(primary, red-1);
                }
            }
        }

        .list-socials {
            display: flex;
            font-size: 24px;
            color: color(primary, gray-1);
            gap: 24px;
        }

        .time {
            font-size: 18px;
            font-weight: 700;
            color: color(primary, gray-1);

            @include for-phone-and-tablet {
                font-size: 16px;
            }
        }
    }
}
