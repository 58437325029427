@use "../abstracts" as *;

:root {
    font-size: 62.5%;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;

    :focus {
        outline: none;
    }
}

body {
    background-color: #fff;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
}

a {
    color: inherit;
    font-size: inherit;

    &:hover {
        color: color(primary, gray-3);
        text-decoration: unset;
    }
}

img {
    display: block;
    max-width: 100%;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
p {
    margin: 0;
}
