@use "../abstracts" as a;

%linkColor {
    color: a.color(primary);
}

.page {
    &-link {
        @extend %linkColor;

        &:hover {
            @extend %linkColor;
        }
    }

    &-item {
        &.active > .page-link {
            background-color: a.color(primary, 500);
            border-color: a.color(primary, 500);
        }
    }
}
