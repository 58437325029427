@use "../abstracts" as *;

.banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 230px;
    background-image: url("../../../images/placeholder/web/banner-pages.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include for-tablet {
        margin-top: 32px;
    }

    &__figure {
        margin-bottom: 8px;
    }

    &__image {
        width: 0px;
    }

    &__title {
        font: 900 38px/40px "Roboto", sans-serif;
        color: color(primary, gray-4);
        text-align: center;

        @include for-phone-only {
            font-size: 40px;
        }

        &::after {
            content: "";
            display: block;
            height: 4px;
            margin: 1.2rem auto;
            background-color: color(primary, red-1);
            border-radius: 8px;
        }
    }
}
