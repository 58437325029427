@use "../abstracts" as *;

.footer {
    .rights {
        padding: 12px 0;
        background-color: color(primary, red-1);

        &__info {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            text-align: center;
            gap: 16px;

            p {
                font-size: 14px;
                color: #fff;
            }

            @include for-desktop-up {
                flex-direction: row;
                text-align: left;
                gap: 0;
            }
        }
    }
}
