@use "../abstracts" as *;

.header__nav {
    @include for-phone-only {
        margin-right: 1.5rem;
    }

    @include for-desktop-up {
        .row {
            justify-content: center;
        }
    }
}

.nav {
    &__list {
        @include flex(flex, row, center, space-between);
        gap: 40px;

        @include for-tablet-portrait-only {
            font-size: 16px;
        }

        @include for-phone-and-tablet-large {
            @include flex(flex, column, center, flex-start);

            padding-top: 4px;
            gap: 0;

            .nav__item {
                padding: 8px 0;

                @include for-phone-only {
                  border-bottom: solid 1px #d6d6d6;
                  width: 100%;
                  text-align: center;
                }

            }
        }

        &--drop {
            top: 100%;
            left: 0;
            z-index: 100;
            flex-direction: column !important;
            align-items: flex-start !important;
            justify-content: flex-start !important;
            gap: 0;
            padding: 0.5rem;
        }
    }

    &__item {
        padding: 8px 0;
        transition: all 0.7s;
        position: relative;
        
        @include for-phone-only {
          border-bottom: solid 1px color(grayScale, 100);
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            background-color: red;
        }
        &:hover {
            &::after {
                width: 100%;
            }
        }

        &--drop {
            position: relative;

            // span {
            //     @include for-phone-only {
            //         justify-content: flex-start !important;
            //     }
            // }

            &-item {
                position: relative;
                width: 100%;

                a {
                    padding: 0.5rem 0;
                }

                &::after {
                    background-color: color(primary, red-1);
                }
            }

            &:hover {
                background-color: white;

                span {
                    color: color(primary, red-1) !important;
                }

                & > ul {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    &__link {
        padding: 0 0.5rem;

        &:hover {
            color: currentColor;
        }
    }

    @include for-desktop-up {
        &__content {
            display: block;
        }

        &__list {
            @include flex(flex, row, center, space-between);

            &--drop {
                position: absolute;
                min-width: 200px;
                opacity: 0;
                visibility: hidden;
                background-color: white;

                a {
                    color: color(blackScale, 300) !important;
                }
            }
        }

        &__item {
            &--drop {
                &-item {
                    a {
                        align-items: flex-start;
                    }

                    &:not(:last-of-type) {
                        border-bottom: 1px solid #e8e8e8;
                        margin-left: unset;
                    }
                }
            }
        }

        &__item,
        &__link {
            height: 100%;
        }
    }
}

.nav-mobile {
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 100;
    width: 100%;
    height: 100%;

    &__overlay {
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 99;
        width: 100%;
        height: 100%;
        background-color: #fff;
    }

    &__close {
        text-align: right;

        margin: 2rem 1rem 0 0;
        color: color(primary, red-1);
    }

    &__button {
        width: 100%;
        padding: 16px;
        color: color(primary, gray-3);
    }

    @include for-desktop-up {
        position: static;
        width: 100%;
        background-color: transparent;

        &__button,
        &__close {
            display: none;
        }
    }

    &__active,
    &__active > &__overlay {
        right: 0;
    }
}
